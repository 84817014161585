import { AllChallengesConst } from "../const/allChallengesConst";

export const generateChallengesHelper = () => {
    // Copie des challenges pour éviter de modifier l'objet d'origine
    const challengesCopy = [...AllChallengesConst];

    // Mélanger les challenges pour garantir une sélection aléatoire
    const shuffledChallenges = challengesCopy.sort(() => Math.random() - 0.5);

    // Sélectionner les 3 premiers challenges après mélange
    const selectedChallenges = shuffledChallenges.slice(0, 3);

    // Gérer les quantités aléatoires si un tableau est présent
    const challengesWithRandomQuantities = selectedChallenges.map((challenge) => {
      if (Array.isArray(challenge.quantity) && challenge.quantity.length > 0) {
        // Choisir une valeur aléatoire dans le tableau quantity
        const randomQuantity = challenge.quantity[Math.floor(Math.random() * challenge.quantity.length)];
        const points = challenge.points * randomQuantity;
        return { ...challenge, quantity: randomQuantity, points: points };
      }
      return challenge; // Pas besoin de modification si quantity n'est pas un tableau
    });

    return challengesWithRandomQuantities;

};
