import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AuthenticationContext } from "../../authentication/authenticationContext";
import { AnaDiscussionStatusType } from "../../notification/type/anaDiscussionStatusType";

export const useAnaDiscussionStatus = () => {
    const initialState:AnaDiscussionStatusType = {} as AnaDiscussionStatusType
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {user, isAuthLoading, isSigning, logOut, isNewUser, cloudStorage, wasUserNotConnected} = useContext(AuthenticationContext)
    const dataFromNotConnecterRef = useRef<AnaDiscussionStatusType|null>(null);

    type Action =
        | { type: 'SET_FIRST_MESSAGE'; payload: boolean }
        | { type: 'SET_OBTAIN_FIRST_CREATURE_MESSAGE'; payload: boolean }
        | { type: 'SET_OBTAIN_FIRST_FOOD_MESSAGE'; payload: boolean }
        | { type: 'SET_FIRST_FEED_MESSAGE'; payload: boolean }
        | { type: 'SET_EXPLAIN_HOW_TO_FEED'; payload: boolean }
        | { type: 'SET_EXPLAIN_HOW_TO_PLAY'; payload: boolean }
        | { type: 'SET_EXPLAIN_HOW_TO_PLAY_QUEST'; payload: boolean }
        | { type: 'SET_EXPLAIN_EGGS'; payload: boolean }
        | { type: 'SET_NEW_EGG'; payload: boolean }
        | { type: 'SET_NEW_FEATHER'; payload: boolean }
        | { type: 'SET_FIRST_HELP_ZONE_MESSAGE'; payload: boolean }
        | { type: 'SET_DOUBLE_CARD_FRIENDS_MESSAGE'; payload: boolean }
        | { type: 'SET_BADGE_FIRST_MESSAGE'; payload: boolean }
        | { type: 'SET_EXPLAIN_SHOPPING'; payload: boolean }
        | { type: 'UPDATE_ALL'; payload: AnaDiscussionStatusType }
        | { type: 'UPDATE_FROM_CLOUD'; payload: AnaDiscussionStatusType }
        | { type: 'EMPTY' }


    const reducer= (state:AnaDiscussionStatusType, action:Action) => {
        switch (action.type) {
            case 'SET_FIRST_MESSAGE':
                return { ...state, firstMessage: action.payload, isSynchronized: false  };
            case 'SET_OBTAIN_FIRST_CREATURE_MESSAGE':
                return { ...state, obtainFirstCreatureMessage: action.payload, isSynchronized: false  };
            case 'SET_EXPLAIN_SHOPPING':
                return { ...state, explainShopping: action.payload, isSynchronized: false  };
            case 'SET_NEW_EGG':
                return { ...state, gotFirstEgg: action.payload, isSynchronized: false};
            case 'SET_NEW_FEATHER':
                return { ...state, gotFeather: action.payload, isSynchronized: false};
            case 'SET_OBTAIN_FIRST_FOOD_MESSAGE':
                return { ...state, obtainFirstFoodMessage: action.payload, isSynchronized: false };
            case 'SET_FIRST_FEED_MESSAGE':
                return { ...state, firstFeedMessage: action.payload, isSynchronized: false  };
            case 'SET_EXPLAIN_HOW_TO_FEED':
                return { ...state, explainHowToFeed: action.payload, isSynchronized: false  };
            case 'SET_EXPLAIN_HOW_TO_PLAY':
                return { ...state, explainHowToPlay: action.payload, isSynchronized: false  };
            case 'SET_EXPLAIN_HOW_TO_PLAY_QUEST':
                return { ...state, explainHowToPlayQuest: action.payload, isSynchronized: false  };
            case 'SET_EXPLAIN_EGGS':
                return { ...state, explainEggs: action.payload, isSynchronized: false  };
            case 'SET_FIRST_HELP_ZONE_MESSAGE':
                return { ...state, firstHelpZoneMessage: action.payload, isSynchronized: false  };
            case 'SET_DOUBLE_CARD_FRIENDS_MESSAGE':
                return { ...state, doubleCardFriendsMessage: action.payload, isSynchronized: false  };
            case 'SET_BADGE_FIRST_MESSAGE':
                return { ...state, badgeFirstMessage: action.payload, isSynchronized: false  };
            case 'UPDATE_ALL':
                return {...action.payload, isSynchronized: false };
            case 'UPDATE_FROM_CLOUD':
                return {...action.payload, isSynchronized: true };
            case 'EMPTY':
                return {} as AnaDiscussionStatusType;

            default:
                return state;
        }
     }
    const [state, dispatch] = useReducer(reducer, initialState);

    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
        if ( process.env.NODE_ENV==="development" ) {
            console.log("anadiscussion change", state)
        }
    }, [state]);

    const update=(field: any, value:boolean)=>{
        if ( !field ) { return }
        dispatch({
            type: field, 
            payload: value})
    }

    const updateAll= (state:AnaDiscussionStatusType)=>{
        if ( !state ||  !Object.keys(state).length ) { return }
        dispatch({
            type: 'UPDATE_ALL', 
            payload: state})
    }

    const emptyState=()=>{
        dispatch({
            type: 'EMPTY'})
    }

    useEffect(()=>{
        if(user && !isAuthLoading && cloudStorage){
            cloudStorage?.getAnaDiscussionFromCloud().then((status)=>{  
                if ( status ){
                    dispatch({
                        type: 'UPDATE_FROM_CLOUD', 
                        payload: status})
                }else{
                    setIsLoading(false)
                }
            })

            /*const unsubscribe = cloudStorage?.subscribeToAnaDiscussionStatus(
                (status)=>{
                    if ( status ){
                        dispatch({
                            type: 'UPDATE_FROM_CLOUD', 
                            payload: status})
                    }else{
                        setIsLoading(false)
                    }
                },
                setIsLoading
            )*/

            return () => {
               // if ( unsubscribe ) { unsubscribe() }
            }
        }
    },[user, isAuthLoading, cloudStorage])

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            emptyState()
            setIsLoading(false)
        }
    },[user, isAuthLoading])

    useEffect(()=>{
        if ( cloudStorage && user && state &&  Object.keys(state).length !== 0  ){
            setIsLoading(false)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setAnaDiscussionToCloud preSave")
            }
            if ( !state.isSynchronized ) {
                cloudStorage?.setAnaDiscussionToCloud(state).then(result=>{
                    dispatch({
                        type: 'UPDATE_FROM_CLOUD', 
                        payload: state
                    })
                })
            }
        }
    }, [cloudStorage, user, state])

    useEffect(()=>{
        if (isSigning) {
            if ( wasUserNotConnected){                
                dataFromNotConnecterRef.current = state               
            }      
            emptyState()
            setIsLoading(true)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("wasUserNotConnected", state)
            }
        }
    },[isSigning, wasUserNotConnected])

    useEffect(()=>{
        if ( logOut ){
            emptyState()
        }
    },[logOut])

    useEffect(()=>{
        if ( !isLoading && user && isNewUser && (!state || Object.keys(state).length==0 ) && dataFromNotConnecterRef.current && Object.keys(dataFromNotConnecterRef.current).length !== 0 ){
            updateAll(dataFromNotConnecterRef.current)
            dataFromNotConnecterRef.current = null
        }
    },[isLoading, user ])

    return {
        state,
        isLoading,
        update
    }
}