import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { Text, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { QrReader, QrReaderProps } from "react-qr-reader"; // Import the correct path for QrReader
import jwt from "jsonwebtoken";
import { NotificationContext } from "@/app/context/notification/notificationContext";
import { FirebaseContext } from "@/app/context/firebase/firebaseContext";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import useFriendRequests from "@/app/hooks/useFriendRequests";
import { newFriendMessage } from "@/app/context/notification/helper/anaDiscussionHelper";

type Props = {
    isOpen: boolean;
    handleClose: () => void;
};

export default function ScanQrCodeForFriends({isOpen, handleClose}:Props) {
    const { t } = useTranslation();
    const {
        cloudStorage,
        user
    } = useContext(AuthenticationContext)

    const {
        setDoVibrate,
        addError,
        addMessageWithId,
        character
    } = useContext(NotificationContext)

    const {
        logAnalyticsEvent
    } = useContext(FirebaseContext)

    const {
        addOrUpdateActionHistory
    } = useContext(GlobalDataUserContext)   

    const {
        qrCodeScanned,
        setQrCodeScanned
    } = useContext(GlobalDataContext)
    
    const lastScanRef = useRef({ data: "", timestamp: 0 });

    useEffect(()=>{
        if (qrCodeScanned){
            handleScan(qrCodeScanned).then(result=>{
                setQrCodeScanned(false)
            })    
        }
    },[qrCodeScanned])

    const handleScan = async(data: string | null) => {
        if (data) {
            const now = Date.now();
            if (data === lastScanRef.current.data && (now - lastScanRef.current.timestamp) < 3000) {
                return; // Ignorer les scans répétés dans un intervalle de 3 secondes
            }
            lastScanRef.current = { data, timestamp: now };

            try {
                const urlParams = new URLSearchParams(new URL(data).search);
                const friendrequest = urlParams.get('friendrequest');
                if (!friendrequest || friendrequest=="") throw new Error("Invalid QR code");
                const decoded = jwt.verify(friendrequest, process.env.NEXT_PUBLIC_FRIENDREQUEST_SECRET_KEY as string) as { senderId: string };

                cloudStorage?.getUsersInfo([decoded.senderId]).then(users=>{
                    if ( users && users.length>0 ){
                        cloudStorage?.addFriendRequests(decoded.senderId,"approved").then(result=>{
                            if ( result === false) throw new Error("Invalid QR code");
                            addOrUpdateActionHistory("friends",1)
                            setDoVibrate(true)
                            addError(t("friends.Demande d'ami acceptée"), "info")
                            newFriendMessage(addMessageWithId, t, character?.translationKey)
                            logAnalyticsEvent("scan_friend",{})
                            handleClose()
                        })
                        .catch(err => {
                            console.error("Error during friend request process:", err);
                            addError(t("friends.Impossible d'ajouter cette personne."), "error");
                            handleClose();
                          });                            
                    }else{
                        throw new Error("Invalid QR code");
                    }
                })
                .catch(err => {
                    console.error("Error during friend request process:", err);
                    addError(t("friends.Impossible d'ajouter cette personne."), "error");
                    handleClose();
                  });                
            } catch (err) {
                console.error("Invalid QR code", err);
                addError(t("friends.Impossible d'ajouter cette personne."), "error")
                handleClose()
            }
        }
        
    }
    
    return (
        <>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader pt={2} pb={0} textAlign={"center"}>{t("friends.addFriend")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        p={0}
                    >   
                        <Text textAlign="center">{t("friends.Scan QR Code to add a friend")}</Text>
                        <QrReader     
                            onResult={(result, error) => {
                                if (!!result) {
                                    if ( process.env.NODE_ENV==="development" ) {
                                        console.log(result)
                                    }
                                    const text = result.getText() ;                
                                    handleScan(text)
                                    //handleScan(result?.text);
                                }
            
                                if (!!error) {
                                    //handleError(error);
                                }
                            }}  
                            containerStyle={{ width: "100%" }} // Add the containerStyle property    
                            constraints={{ facingMode: "environment" }} // Add the constraints property
                        />
                    </ModalBody>                
                    <ModalFooter px={2} pt={0} pb={2} display="flex">
                        <Button
                            colorScheme="gray"
                            size="sm"
                            onClick={handleClose}
                        >
                            {t("friends.Fermer")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}