import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GlobalDataUserType } from '../../types/globalDataUserType';
import { ConfigType } from '../../types/configType';
import { ScoreType } from '../../types/scoreType';
import { AuthenticationContext } from '../authentication/authenticationContext';
import { FirebaseContext } from '../firebase/firebaseContext';
import { getLevel, getLevelName } from '../../helpers/levelConversionHelper';
import { MyCreaturesType } from '../../types/myCreaturesType';
import { CreaturesConst } from '../../const/creaturesConst';
import { CreatureDefinitionType } from '../../types/creatureDefinitionType';
import ConfettiExplosion from 'react-confetti-explosion';

import { useDisclosure } from '@chakra-ui/react';
import { v4 } from "uuid";
import NewCreatureModal from './layout/modal/newCreatureModal';
import { NotificationContext } from '../notification/notificationContext';
import { ActionHistoryType } from './type/actionHistoryType';
import { ZoneType } from '@/app/types/zoneType';
import FinishGameModal from '../game/layout/modal/finishGameModal';
import { GlobalDataContext } from './globalDataContext';
import { getDistance } from '@/app/helpers/mapHelper';
import { useTranslation } from 'next-i18next';
import { AllBadges } from '@/app/const/allBadgesConst';
import ValidateUserNameModal from './layout/modal/validateUserName';
import { TradeRequestType } from '@/app/types/tradeRequestType';
import ObtainFoodModal from './layout/modal/obtainFoodModal';
import { useMyCreatures } from './hooks/useMyCreatures';
import { useActionHistory } from './hooks/useActionHistory';
import { useTradeRequests } from './hooks/useTradeRequests';
import { useAnaDiscussionStatus } from './hooks/useAnaDiscussionStatus';
import { useUserState } from './hooks/useUserState';
import useAudio from './hooks/useAudio';
import LooseGameModal from '../game/layout/modal/looseGameModal';
import { SwitchCreature } from '@/app/layout/game/modal/switchCreature';
import { add, debounce } from 'lodash';
import { useMyItems } from './hooks/useMyItems';
import { MyItemsType } from '@/app/types/myItemsType';
import NewEggModal from './layout/modal/newEggModal';
import { PlayersType } from '@/app/types/playersType';
import { ItemsType } from '@/app/types/itemsType';
import { calculateElevationGain, easyThreshold, generateRandomPath, getRandomIndices, getRoute, mediumThreshold } from '@/app/helpers/generateRandomPathHelper';
import { getDataLocalStorageHelper, storeDataWithDateLocalStorageHelper } from '@/app/helpers/localStorageHelper';
import { geohashForLocation } from 'geofire-common';
import { fartMessage, newBadgeMessage, newLevelMessage, winChallenge, winOneChallenge } from '../notification/helper/anaDiscussionHelper';
import { MyArtifactType } from '@/app/types/myArtifactType';
import { UnblockedType } from '@/app/types/unblockedType';
import NewDynamicItemModal from '../game/layout/modal/newDynamicItemModal';
import { UnblockedConst } from '@/app/const/unblockedConst';
import { generateChallengesHelper } from '@/app/helpers/generateChallengesHelper';
import { ChallengeType } from '@/app/types/challengeType';
import DailyChallengeDoneModal from './layout/modal/dailyChallengeDoneModal';

export const GlobalDataUserContext = createContext({} as GlobalDataUserType);

export const GlobalDataUserProvider = (props:any) => {
    const { t } = useTranslation();
    const lastExecutionTimeRef = useRef(Date.now()); // Utiliser un ref pour conserver l'état entre les rendus
    const [isZoneLoading,setIsZoneLoading] = useState(true)
    const [config,setConfig] = useState<ConfigType|null>(null)
    const [score,setScore] = useState<ScoreType|null>(null)
    const [userInfo,setUserInfo] = useState<any|null>(null)
    const [myArtifacts,setMyArtifacts] = useState<MyArtifactType[]|null>(null)
    const [showConfetti, setShowConfetti] = useState(false);
    const { isOpen:isOpenUsername, onOpen:onOpenUsername, onClose:onCloseUsername } = useDisclosure();
   
    const [creatureToSwitch, setCreatureToSwitch] = useState<CreatureDefinitionType|null>(null)
    const [myZones, setMyZones] = useState<ZoneType[]>([])    
    const [lastLevel, setLastLevel] = useState<number|null>(null)
    const [lastTimeCheckingForNewBadges, setLastTimeCheckingForNewBadges] = useState<number|null>(null) 
    const [showFriends, setShowFriends] = useState(false)
    const [isReadyForAna, setIsReadyForAna] = useState(false)
    const [allPlayersPositions, setAllPlayersPositions] = useState<PlayersType[]>([])
    const [allItems, setAllItems] = useState<ItemsType[]>([])
    const [oldLocation, setOldLocation] = useState<any>(null)
    const [dailyNaturePhotoCount, setDailyNaturePhotoCount] = useState<number>(0)

    const [showChallengeIndexes, setShowChallengeIndexes] = useState<number[]>([])  
    
    const emptyChallengeIndexes = () => {
        setShowChallengeIndexes([])
    }
    const {
        state: myCreatures,
        isLoading: isMyCreaturesLoading,
        add: addCreatureReducer,
        makeLaugh: makeLaughReducer,
        feed: feedReducer
    } = useMyCreatures()

    const {
        state: myItems,
        isLoading: isMyItemsLoading,
        add: addItemReducer,
        care: careReducer
    } = useMyItems()

    const {
        state: actionHistory,
        isLoading: isActionHistoryLoading,
        updateByDay: addOrUpdateActionHistory,
        checkActionHistory ,
        getChallenge  
    } = useActionHistory()

    const {
        state: tradeRequests,
        isLoading : isTradeRequestsLoading,
        add: addTradeRequestReducer,
    } = useTradeRequests()

    const {
        state: anaDiscussionStatus,
        isLoading: isAnaDiscussionStatusLoading,
        update: updateAnaDiscussionStatus,
    } = useAnaDiscussionStatus()

    const {
        state: userState,
        isLoading: isUserStateLoading,
        addPoints: addPointsReducer,
        addXp: addXpReducer,
        setGooblies: setGoobliesReducer,
        addBadge: addBadgeReducer,
        addKilometers: addKilometersReducer,
        addFood: addFoodReducer,
        removeFood: removeFoodReducer,
        gotBonusFromBadge: gotBonusFromBadgeReducer,
        addUnblocked: addUnblockedReducer
    } = useUserState()

    const audioFiles = useMemo(() => [
        { id: "woohoo01", src: "/mp3/woohoo01.mp3" },
        { id: "yummy01", src: "/mp3/yummy01.mp3" },
        { id: "win01", src: "/mp3/win01.mp3" },
        { id: "fart01", src: "/mp3/fart01.mp3" },
        { id: "fart02", src: "/mp3/fart02.mp3" },
        { id: "fart03", src: "/mp3/fart03.mp3" },
        { id: "fart04", src: "/mp3/fart04.mp3" },
        { id: "fart05", src: "/mp3/fart05.mp3" },
        { id: "laugh01", src: "/mp3/laugh01.mp3" },
        { id: "beep01", src: "/mp3/beep01.mp3" },
        { id: "swallow01", src: "/mp3/swallow01.mp3" },
        { id: "ding01", src: "/mp3/ding01.mp3" },
        {id: "magic01", src: "/mp3/magic01.mp3"},
        {id: "gameover", src: "/mp3/gameover.mp3"},
        {id: "shopping01", src: "/mp3/shopping01.mp3"},
        {id: "burn01", src: "/mp3/burn01.mp3"},
        {id: "grass01", src: "/mp3/grass01.mp3"},
        {id: "whoosh01", src: "/mp3/whoosh01.mp3"},
        {id: "witch01", src: "/mp3/witch01.mp3"},
        {id: "halloween01", src: "/mp3/halloween01.mp3"},
      ], []);
      
    const {
        play
    } = useAudio(audioFiles);

    const {
        addInfo,
        addError,
        setDoVibrate,
        addMessageWithId,
        emptyMessages,
        addModalToQueue,
        character
    } = useContext(NotificationContext)

    const {
        user,
        isAdmin,
        cloudStorage,
        isAuthLoading,
        messagingToken,
        sendNotificationPush,
        isSigning,
        logOut
        //subscribeNewsletter,
        //setSubscribeNewsletter
    } = useContext(AuthenticationContext)

    const {
        authState,
        isFirebaseLoading,
        logAnalyticsEvent
    } = useContext(FirebaseContext)

    const {
        location,
        firstLocation,
        setFirstLocation,
        isTranslationsLoaded,
        browserActive,
        pushToken,
        language
    } = useContext(GlobalDataContext)

    const addArtifactIfNotExist=(artifact:MyArtifactType)=>{
        if ( myArtifacts && myArtifacts.length){
            if ( !myArtifacts.find((item)=>item.id == artifact.id)){
                setMyArtifacts([...myArtifacts, {...artifact, timestamp:Date.now()}])
                if ( config && cloudStorage && !isAuthLoading && user){  
                    cloudStorage?.setMyArtifactsToCloud([{...artifact, timestamp:Date.now()}]).then()
                }
            }
        }else{
            setMyArtifacts([{...artifact, timestamp:Date.now()}])
            if ( config && cloudStorage && !isAuthLoading && user){  
                cloudStorage?.setMyArtifactsToCloud([{...artifact, timestamp:Date.now()}]).then()
            }
        }        
    }

    useEffect(()=>{
        if ( config && cloudStorage && !isAuthLoading && user){            
            cloudStorage?.setConfigToCloud(config).then()
        }
    },[config, cloudStorage, user, isAuthLoading])

    const positionsQueue = useRef<any>({});
    const timeoutRef = useRef<any>(null); // Référence du timeout

    useEffect(()=>{
        if (userInfo && userInfo.isAdmin && !isAuthLoading && cloudStorage) {
            // File d'attente pour stocker les positions des joueurs, où l'userId est la clé
            
    
            // Fonction pour ajouter ou mettre à jour les positions dans la file d'attente
            const updateQueue = (data:any) => {
                data.forEach((player:any) => {
                    positionsQueue.current[player.userId] = player; // Remplace la position du joueur
                });
    
                // Si le timeout n'est pas déjà défini, le démarrer
                if (!timeoutRef.current) {
                    startTimeout();
                }
            };
    
            // Fonction qui vide la file d'attente et met à jour l'état des positions
            const flushQueue = () => {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("flushQueue")   
                }  
                setAllPlayersPositions(prevState => {
                    const updatedPositions = Object.values(positionsQueue.current); // Récupère les valeurs (positions) de la file d'attente
                    const currentTime = Date.now(); // Obtiens l'heure actuelle en millisecondes

                    // Filtrer les positions dont le expiresAt n'est pas dépassé
                    const validPositions = updatedPositions.filter((player: any) => player.expiresAt && player.expiresAt > currentTime);

                    
                    const playersToAdd = validPositions.filter((player:any) => !prevState.find((prevPlayer) => prevPlayer.userId === player.userId)) || [];
                    const playersToModify = validPositions.filter((player:any) => prevState.find((prevPlayer) => prevPlayer.userId === player.userId)) || [];
    
                    // Vider la file d'attente après la mise à jour
                    positionsQueue.current = {};
    
                    return [
                        ...playersToAdd,
                        ...prevState.map((prevPlayer) => {
                            return playersToModify.find((player:any) => player.userId === prevPlayer.userId) || prevPlayer;
                        }) || []
                    ] as PlayersType[];
                });
    
                // Réinitialise le timeout une fois la file vidée
                timeoutRef.current = null;
            };
    
            // Démarre un timeout pour exécuter flushQueue après 2 secondes
            const startTimeout = () => {
                timeoutRef.current = setTimeout(() => {
                    flushQueue(); // Vider la file d'attente
                }, 2000);
            };
    
            // Ecoute les positions des joueurs en temps réel
            const unsubscribe = cloudStorage?.listenToPlayersPositions((data) => {
                updateQueue(data); // Met à jour la file d'attente avec les nouvelles positions
            });
    
            return () => {
                if (unsubscribe) unsubscribe();
                if (timeoutRef.current) clearTimeout(timeoutRef.current); // Nettoyer le timeout au démontage
            };
        }
    },[userInfo, cloudStorage, isAuthLoading])

    useEffect(()=>{
        if ( myZones && myZones.length){
            setIsZoneLoading(false)
        }
    },[myZones])

    useEffect(() => {
        const FIVE_MINUTES = 5 * 60 * 1000;
        const currentTime = Date.now();
        const timeSinceLastExecution = currentTime - lastExecutionTimeRef.current;

        if (timeSinceLastExecution >= FIVE_MINUTES) {
            if (!isAuthLoading && user && location && cloudStorage) {
                if (!oldLocation || (oldLocation.lat !== location.lat || oldLocation.lng !== location.lng)) {
                    cloudStorage?.setCoordinatesToCloud(location.lat, location.lng).then(() => {
                        setOldLocation(location);
                        lastExecutionTimeRef.current = Date.now(); // Mettre à jour le timestamp
                    });
                }
            }
        }
    }, [location, user, isAuthLoading, cloudStorage, oldLocation]);

    useEffect(()=>{
        if ( userState ){
            //setIsUserStateLoading(false)
            setLastLevel(getLevel(userState?.points || 0))
        }
    },[userState])

    useEffect(()=>{
        if ( userInfo && messagingToken && cloudStorage && config ){
            cloudStorage?.setUserInfoToCloud({
                ...userInfo,
                token:config.allowNotifications ? messagingToken : ""
            }).then()
        }
    },[userInfo, messagingToken, config, cloudStorage])

    useEffect(()=>{
        if ( userInfo && pushToken && cloudStorage && config ){
            cloudStorage?.setUserInfoToCloud({
                ...userInfo,
                expoToken:config.allowNotifications ? pushToken : ""
            }).then()
        }
    },[userInfo, pushToken, config, cloudStorage])

    const onCloseUsernameModal = () => {
        onCloseUsername()
        if ( user && !isAuthLoading ){
            cloudStorage?.getUserInfo().then((info)=>{
                setUserInfo(info)
            })
        }
    }

    useEffect(()=>{
        if ( cloudStorage && user && !isAuthLoading ){
            cloudStorage?.getUserInfo().then((info)=>{
                setUserInfo(info)
                if (!info || !info.userName){
                    onOpenUsername()
                }
            })
            cloudStorage?.getMyArtifactsFromCloud().then((artifacts)=>{
                if ( artifacts){
                    setMyArtifacts(artifacts)
                }
            })
        }
    },[user, isAuthLoading, cloudStorage])

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            setConfig(null)
            setLastLevel(0)
            setMyZones([])
            setIsZoneLoading(false)
            emptyMessages()
            setIsReadyForAna(true)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("not connected and no user : empty data")   
            }            
        }              
    },[user, isAuthLoading])

    useEffect(()=>{
        setConfig(null)
        setMyZones([])
        emptyMessages()           
        setIsReadyForAna(false) 
        setLastLevel(0)
    },[logOut])

    useEffect(()=>{
        if ( isSigning){
            setLastLevel(0)
            setConfig(null)
            setMyZones([])
            emptyMessages()             
            setIsReadyForAna(false) 
        }
    },[isSigning])

    useEffect(()=>{
        if ( cloudStorage && !isAuthLoading && user ){
            cloudStorage?.getConfigFromCloud().then(result=>{
                setConfig(result)
            })            
        }

    },[cloudStorage,user,isAuthLoading])    

    useEffect(()=>{
        if ( cloudStorage && !isAuthLoading &&  character !==null && !isMyCreaturesLoading && !isUserStateLoading && !isActionHistoryLoading && !isAnaDiscussionStatusLoading && user ) {
            setIsReadyForAna(true)
        }
    },[isAuthLoading, cloudStorage, isMyCreaturesLoading, character, isUserStateLoading, isActionHistoryLoading, isAnaDiscussionStatusLoading, user])

    useEffect(()=>{
        if ( !location || !firstLocation)
            return
        const distance = getDistance(
            {
                latitude: location.lat,
                longitude: location.lng
            },
            {
                latitude: firstLocation.lat,
                longitude: firstLocation.lng
            },
            1
        )
        if ( distance > 1000 ){
            if ( process.env.NODE_ENV==="development" ) {
                console.log("distance > 1000m")
            }
            setFirstLocation(location)
            setMyZones([])
        }
        
    },[])

    useEffect(()=>{
        if ( cloudStorage && !isAuthLoading && user && firstLocation){
            const unsubscribeZone = cloudStorage?.subscribeToZone(
                firstLocation,
                (zone)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("unsubscribeZone", zone)
                    }
                    addZoneFromCloud(zone)
                },
                setIsZoneLoading
            )
            return () => {
                if ( unsubscribeZone && unsubscribeZone[0]) { unsubscribeZone[0]() }
                if ( unsubscribeZone && unsubscribeZone[1]) { unsubscribeZone[1]() }
            }
        }
    },[cloudStorage,user,isAuthLoading,firstLocation])   

    useEffect(()=>{
        if ( myCreatures && myCreatures.length > 0 && cloudStorage && user && !isAuthLoading && !isUserStateLoading){
            //const checkSynchronized = myCreatures.find((creature:MyCreaturesType)=>!creature.isSynchronized)
            if ( userState?.gooblies !== myCreatures.length){
                setGoobliesReducer(myCreatures.length)
            }
        }
    },[myCreatures,cloudStorage, user, isAuthLoading, isUserStateLoading])

    useEffect(()=> {
        if ( process.env.NODE_ENV==="development" ) {
            console.log("WTF LASTLEVEL", lastLevel)
        }
        if ( !(lastLevel == null || lastLevel ==0 )&& lastLevel !== getLevel(userState?.points || 0)){
            setLastLevel(getLevel(userState?.points || 0))
            newLevelMessage(addMessageWithId, t, userState, character?.translationKey)
            play("woohoo01")
            logAnalyticsEvent("new_level",{})
        }
    },[userState])
    
    useEffect(()=>{
        if ( user && !isActionHistoryLoading){
            addOrUpdateActionHistory("connection", 1)
            if ( !checkActionHistory("challenge") ){ 
                const challenges = generateChallengesHelper()                
                addOrUpdateActionHistory("challenge", 1, challenges)
            }
        }
    },[user, isActionHistoryLoading])    
    
    useEffect(()=>{
        if ( isReadyForAna && actionHistory && user && !isAuthLoading && !isActionHistoryLoading && !isUserStateLoading){
            const checkSynchronized = actionHistory.find((action: ActionHistoryType)=>!action.isSynchronized)
            if ( !checkSynchronized ) {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("checkForNewBadges", actionHistory, isActionHistoryLoading, isUserStateLoading)
                }
                debouncedCheckForNewBadges()
            }
        }
    },[isReadyForAna && actionHistory, isActionHistoryLoading, isUserStateLoading, user ,isAuthLoading])

    useEffect(()=>{
        if ( actionHistory ){
            const dailyChallenge = getChallenge()
            if ( dailyChallenge && dailyChallenge.length){
                const indexChecked: any[] = []
                dailyChallenge.map((challenge:ChallengeType, index:number)=>{
                    if ( !challenge.checked){                        
                        switch(challenge.code){
                            case "walk":
                                const history = checkActionHistory("kilometer")
                                if ( history ){
                                    if (history.count >= (challenge.quantity as number) * 1000){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "feed":
                                const historyFeed = checkActionHistory("feed")                                
                                if ( historyFeed ){
                                    if (historyFeed.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "quiz":
                                const historyQuiz = checkActionHistory("quizz")
                                if ( historyQuiz ){
                                    if (historyQuiz.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "cultural":
                                const historyCultural = checkActionHistory("cultural")
                                if ( historyCultural ){
                                    if (historyCultural.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "incubate":
                                const historyIncubate = checkActionHistory("careEgg")
                                if ( historyIncubate ){
                                    if (historyIncubate.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "chase":
                                const historyChase = checkActionHistory("play")
                                if ( historyChase ){
                                    if (historyChase.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "laugh":
                                const historyLaugh = checkActionHistory("laugh")
                                if ( historyLaugh ){
                                    if (historyLaugh.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "eggs":
                                const historyEggs = checkActionHistory("newEgg")
                                if ( historyEggs ){
                                    if (historyEggs.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "gooblies":
                                const historyGooblies = checkActionHistory("newCreature")
                                if ( historyGooblies ){
                                    if (historyGooblies.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "friends":
                                const historyFriends = checkActionHistory("friends")
                                if ( historyFriends ){
                                    if (historyFriends.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                            case "quest":
                                const historyQuest = checkActionHistory("playQuest")
                                if ( historyQuest ){
                                    if (historyQuest.count >= (challenge.quantity as number)){
                                        indexChecked.push(index)
                                    }
                                }
                                break;
                        }                        
                    }                    
                })
                if ( indexChecked.length){                    
                    indexChecked.map((index:number)=>{
                        dailyChallenge[index].checked = true
                    })
                    addOrUpdateActionHistory("challenge", 1, dailyChallenge)
                    setShowChallengeIndexes(indexChecked)
                    let counter = 0
                    let xp= 0
                    dailyChallenge.map((challenge:ChallengeType, index:number)=>{
                        if ( challenge.checked){
                            counter++
                            xp= xp + challenge.points
                        }
                    })
                    if ( counter == dailyChallenge.length && xp > 0){
                        addPoint(xp)
                        addModalToQueue({
                            component: DailyChallengeDoneModal,
                            props: {xp:xp},
                            doConfetti: true,
                            doVibrate: true,
                            onShow: ()=> {play("win01")}
                        })
                        logAnalyticsEvent("challenge_win",{})
                        winChallenge(addMessageWithId, t, character?.translationKey)
                    }else{
                        play("woohoo01")
                        winOneChallenge(addMessageWithId, t, character?.translationKey)
                    }
                }
            }
        }
    },[actionHistory])

    const doLaugh=()=>{
        play("laugh01")
    }

    const doPuff=()=>{
        if ( !config || !config.allowFart){
            return
        }
        setDoVibrate([300])
        const rand = Math.floor(Math.random() * 5) + 1;
        if ( rand == 1 ) {
            play("fart01")
        }else if ( rand == 2 ) {
            play("fart02")
        }else if ( rand == 3 ) {
            let rand = Math.floor(Math.random() * 4) + 1;
            fartMessage(addMessageWithId, t, rand, character?.translationKey)
            play("fart03")
        }else if ( rand == 4 ) {
            play("fart04")
        }else if ( rand == 5 ) {
            play("fart05")
        }
    }

    const addPoint=(quantity:number, xp:number=0)=>{
        if ( quantity > 0){
            addInfo("+ "+quantity+" xp", "success")
        }
        if ( xp > 0){
            //addInfo("+ "+xp+" xp", "success")
        }

        addPointsReducer(quantity)        
        addXpReducer(xp)
    }

    const addFood=(quantity:number)=>{
        addInfo("+ "+quantity+"🧁", "success")
        addFoodReducer(quantity)
        addModalToQueue({
            component: ObtainFoodModal,
            props: {quantity:quantity, description:t("game.winFood")},
            doConfetti: true,
            doVibrate: true,
            onShow: ()=> {play("yummy01")}
        })
    }

    const laughCreature=(id:string)=>{
        doLaugh()
        addPoint(25)
        addOrUpdateActionHistory("laugh", 1)
        makeLaughReducer(id)
        logAnalyticsEvent("laugh",{})
    }

    const careEgg=async (id:string)=>{
        play("burn01")
        addPoint(50)
        addOrUpdateActionHistory("careEgg", 1)
        const egg = myItems.find((egg:MyItemsType)=>egg.id===id)
        if ( egg && egg.levelCare >= 4){
            addNewCreature(null, Date.now(), egg.type)
            await cloudStorage?.deleteItemsFromCloud(id)
        }else{
            careReducer(id)
        }
        logAnalyticsEvent("care",{})
    }

    const addTradeRequest=async(tradeRequest:TradeRequestType)=>{
        if ( !tradeRequest.id ){
            tradeRequest.id = v4()
        }
        addTradeRequestReducer([tradeRequest])
        addPoint(10)
        try{
            logAnalyticsEvent("trade",{})        
            addOrUpdateActionHistory("trade", 1)
            await sendNotificationPush({
                userId : tradeRequest.receiverId, 
                title: t("notifications.title"), 
                body: t("notifications.newTradeRequest")})
        } catch (error: any) {                
        }
        
        addError(t("tradeRequest.tradeRequestSent"), "info")

    }

    const feedCreature=(id:string, quantity=1) => {
        if ( userState && userState.food && userState.food >= quantity ){
        }else if (userState && userState.food && userState.food > 0){
            quantity = userState.food
            addError(t("game.Pas suffisament de nourriture"), "warning")
        }else{
            quantity = 0
            addError(t("game.Plus de nourriture"), "error")
        }
        if ( quantity > 0) {            
            addInfo("-"+quantity+"🧁", "warning")
            addOrUpdateActionHistory("feed", quantity)
            removeFoodReducer(quantity)
            addPoint(50)
            feedReducer(id, quantity)
            play("swallow01")            
            logAnalyticsEvent("feed",{})
        }
    }

    const drawCard = (type:string) => {
        const creaturesList = CreaturesConst.filter((creature)=>creature.type===type)
        const totalWeight = creaturesList.reduce((sum, card) => sum + 1/card.rarity, 0);
        const randomNum = Math.random() * totalWeight;
      
        let cumulativeWeight = 0;
        for (let card of creaturesList) {
            cumulativeWeight += 1/card.rarity;
            if (randomNum <= cumulativeWeight) {     
                return card;
            }
        }
    };

    const addNewEgg=(type:string="NORMAL", win:boolean=false)=>{
        const uuid = v4()
        
        addItemReducer([{
            id:uuid, 
            domain: "eggs",
            type: type,
            lastCare: Date.now() - 1000*60*60*24*1,
            levelCare: 0,
            isSynchronized:false}])
        
        addOrUpdateActionHistory("newEgg", 1)
        
        addPoint(1500,40)
        addModalToQueue({
            component: NewEggModal,
            props: {type:type, description:win ?t("game.winEgg"):t("game.youGotAEgg")},
            doConfetti: true,
            doVibrate: true,
            onShow:()=>{play("woohoo01")}
        })
        logAnalyticsEvent("new_egg",{})
        return 
    }

    const addNewCreature=(creature:CreatureDefinitionType|null, lastFeed=Date.now(), type:string="NORMAL", win:boolean=false)=>{
        const uuid = v4()
        let newCreature= null
        if ( creature ){
            addCreatureReducer([{id:uuid, creatureId:creature.id,discovered:Date.now() ,lastFeed:lastFeed, isSynchronized:false}])
            newCreature = creature
        }else{
            const randomCreature = drawCard(type) as CreatureDefinitionType
            addCreatureReducer([{id:uuid, creatureId:randomCreature.id,discovered:Date.now() ,lastFeed:lastFeed, isSynchronized:false}])
            newCreature = randomCreature
        }    
        addOrUpdateActionHistory("newCreature", 1)
        
        addPoint(1000,25)
        addModalToQueue({
            component: NewCreatureModal,
            props: {newCreature:newCreature, description:win ?t("game.winCreature"):t("game.youGotACreature")},
            doConfetti: true,
            doVibrate: true,
            onShow:()=>{play("woohoo01")}
        })
        logAnalyticsEvent("new_creature",{})
        return newCreature
    }


    const addUnblocked=(unblocked:string)=>{
        const unblockItem = UnblockedConst.find((item:UnblockedType)=>item.name===unblocked)
        addUnblockedReducer(unblockItem?.id as string)
        addPoint(500,10)
        addModalToQueue({
            component: NewDynamicItemModal,
            props: { 
                name: unblockItem?.text[language],
                url: unblockItem?.url,
                description:unblockItem?.unblockText[language]
            },
            doConfetti: true,
            doVibrate: true,
            onShow:()=>{play("woohoo01")}
        })
    }

    const badgesRef = useRef(userState.badges);

    useEffect(() => {
        badgesRef.current = userState.badges;
    }, [userState.badges]);

    const checkForNewBadges=() =>{
        let allowAddBadge = true
        if ( !browserActive ) {
            return
        }
        if ( lastTimeCheckingForNewBadges && (Date.now() - lastTimeCheckingForNewBadges < 5000)){
            return
        }
        setLastTimeCheckingForNewBadges(Date.now())
        AllBadges.forEach(badge => {
            let alreadyUnlocked 
            if ( badgesRef.current && badgesRef.current.length>0){
                alreadyUnlocked = badgesRef.current.some((unlockedBadge:any) => unlockedBadge.id === badge.id);
            }else{
                alreadyUnlocked = false
            }
           if (!alreadyUnlocked && badge.criteria(actionHistory||[]) && allowAddBadge) {
                addBadgeReducer(badge.id);
                newBadgeMessage(addMessageWithId, t, badge, anaDiscussionStatus?.badgeFirstMessage , character?.translationKey)
                if ( !anaDiscussionStatus?.badgeFirstMessage ){
                    updateAnaDiscussionStatus("SET_BADGE_FIRST_MESSAGE", true)                    
                }
                allowAddBadge = false                
            }
        });        
      } 

      const debouncedCheckForNewBadges = debounce(checkForNewBadges, 45000);

    const addZoneFromCloud=(zone:ZoneType[])=>{
        if ( zone && zone.length){
            setMyZones((zoneState)=>{
                const zoneToAdd=[]as ZoneType[]
                zone.map((item)=>{
                    if ( !zoneState.find((zonetItem)=>zonetItem.id == item.id)){
                        zoneToAdd.push(item)
                    }
                })

                return [
                    ...zoneState.map((item)=>{                        
                        return zone.find((zoneItem)=>zoneItem.id == item.id)??item
                    }),
                    ...zoneToAdd
                ]
            })
        }
    }

    const openSwitch=(creature:any)=>{
        setCreatureToSwitch(creature)
    }

    const closeSwitch=()=>{
        setCreatureToSwitch(null)
    }

    const getRandomType = () => {
        const types = ["food", "eggs"];
        return types[Math.floor(Math.random() * (types.length))];
    };

    const increaseNaturePhotoCount = () => {        
        storeDataWithDateLocalStorageHelper(dailyNaturePhotoCount +1 , "dailyNaturePhotoCount" )
        setDailyNaturePhotoCount(dailyNaturePhotoCount + 1);
    }

  useEffect(()=>{
    if ( user && !isAuthLoading &&  location && cloudStorage){

        const localNaturePHotoCount = getDataLocalStorageHelper("dailyNaturePhotoCount")    
        if ( localNaturePHotoCount ){
            setDailyNaturePhotoCount(localNaturePHotoCount)
        }else{
            setDailyNaturePhotoCount(0)
        }
        const items  = getDataLocalStorageHelper()
        if ( items ){
            setAllItems(items)
        }else {
            const result = generateRandomPath(location, 0.5)
            
            getRoute([...result,result[0]], user).then(result=>{
                if ( result && result.coords && result.coords.length) {
                    const indices = getRandomIndices(result?.coords.length,7)
                    const selectedCoords = indices.map((index:number)=>{
                        return result?.coords[index]
                    })
                    if ( !selectedCoords || !selectedCoords.length){
                        return
                    }
                    const newItems = selectedCoords.map((coord:any, index:number)=>{
                        const geohash = geohashForLocation([coord[1], coord[0]]);
                        if ( index == 1 ) {
                            return {
                                id:v4(),
                                geohash: geohash,
                                lat:coord[1],
                                lng:coord[0],
                                sharedType:"public",
                                type:"character",
                                character: "62ca794e-f96b-41c6-88e1-84d103893d4b",
                                expiresAt:Date.now() + 1000*60*60*24*1
                            }
                        }
                        if ( index == 2 ) {
                            return {
                                id:v4(),
                                geohash: geohash,
                                lat:coord[1],
                                lng:coord[0],
                                sharedType:"public",
                                type:"character",
                                character: "13f106b9-cd19-43fb-9155-63af21e78899",
                                expiresAt:Date.now() + 1000*60*60*24*1
                            }
                        }
                        return {
                            id:v4(),
                            geohash: geohash,
                            lat:coord[1],
                            lng:coord[0],
                            sharedType:"public",
                            type:index==0?"feather":getRandomType(),
                            expiresAt:Date.now() + 1000*60*60*24*1
                        }
                    })
                    cloudStorage?.getNearbyUsers(newItems[0], 5).then((users:any)=>{    
                        // Fonction pour mélanger un tableau (utilise l'algorithme de Fisher-Yates)
                        const  shuffleArray=(array: any) =>{
                            for (let i = array.length - 1; i > 0; i--) {
                                const j = Math.floor(Math.random() * (i + 1));
                                [array[i], array[j]] = [array[j], array[i]];
                            }
                            return array;
                        }
                        // Mélanger le tableau users
                        const shuffledUsers = shuffleArray(users);
                        // Sélectionner les 3 premiers éléments du tableau mélangé
                        const randomUsers = shuffledUsers.filter((item:any)=>item.userId !=user.uid).slice(0, 3);
                        //console.log("randomUsers", randomUsers) 
                        randomUsers.forEach((user:any) => {
                            try{                                
                                sendNotificationPush({
                                    userId : user.userId, 
                                    title: t("notifications.title"), 
                                    body: t("notifications.newItemAround")}).then()
                            } catch (error: any) {      
                                if ( process.env.NODE_ENV==="development" ) {
                                    console.log("error notification items", error)
                                }          
                            }
                        })
                    })
                    setAllItems(newItems)
                    storeDataWithDateLocalStorageHelper(newItems)
                }
            })
        }
    }
  },[user,isAuthLoading,firstLocation,cloudStorage])



    return (
        <GlobalDataUserContext.Provider value={{
            isGlobalDataUserLoading: isZoneLoading || isMyItemsLoading || isMyCreaturesLoading || isAnaDiscussionStatusLoading || isUserStateLoading || isActionHistoryLoading,
            isMyCreaturesLoading,
            config,
            score,
            setConfig,
            setScore,
            myCreatures,
            addNewCreature,
            feedCreature,
            addFood,
            actionHistory,
            anaDiscussionStatus,
            updateAnaDiscussionStatus,
            myZones,
            setMyZones,
            userState,
            addKilometersReducer,
            addOrUpdateActionHistory,
            checkActionHistory,
            userInfo,
            doPuff,
            doLaugh,
            laughCreature,
            addTradeRequest,
            tradeRequests,
            setUserInfo,
            gotBonusFromBadgeReducer,
            isReadyForAna, 
            play,
            openSwitch,
            closeSwitch,
            isMyItemsLoading,
            myItems,
            addNewEgg,
            careEgg,
            removeFoodReducer,
            showFriends,
            setShowFriends,
            allPlayersPositions,
            allItems,
            setAllItems,
            creatureToSwitch,
            addPoint,
            setShowConfetti,
            myArtifacts,
            addArtifactIfNotExist,
            addUnblocked,
            getChallenge,
            emptyChallengeIndexes,
            showChallengeIndexes,
            increaseNaturePhotoCount,
            dailyNaturePhotoCount,
        }}>
            {showConfetti &&  
                <ConfettiExplosion 
                    zIndex={1500}
                    force={0.8}
                    duration={2000}
                    particleCount={250}
                    onComplete={()=>setShowConfetti(false)}
            />}  
           {/*{isZoneLoading?"true":"false"} {isMyItemsLoading?"true":"fakse"} {isMyCreaturesLoading?"true":"false"} {isAnaDiscussionStatusLoading?"true":"false"} {isUserStateLoading?"true":"false"} {isActionHistoryLoading?"true":"false"}*/}
             {props.children}                
            <ValidateUserNameModal isOpen={isOpenUsername} onClose={onCloseUsernameModal} />
        </GlobalDataUserContext.Provider>
    )
}