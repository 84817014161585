import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import {
    Button, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, Image,
    Box,
    Badge
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext } from "react";
import { keyframes, css } from '@emotion/react';
const zoomAndShake = keyframes`
   0% {
   -webkit-transform: scale(0.9);
    transform: scale(0.9);
    transform-origin: center;
    opacity: 0;
  }
  50% {
   -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transform-origin: center;
    opacity: 1;
  }
  100% {
   -webkit-transform: scale(1);
    transform: scale(1);
    transform-origin: center;
    opacity: 1;
  }
`;

type Props = {
    isOpen: boolean;
    onClose: () => void;
    xp: number;
};

export default function DailyChallengeDoneModal({isOpen,onClose, xp}:Props) {

    const { t } = useTranslation();
    const {
        showLoginModal,
        setShowLoginModal,
        user,
        //subscribeNewsletter,
        //setSubscribeNewsletter
    } = useContext(AuthenticationContext)
    
    return (
        <Modal isCentered  isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxH="80vh" overflow={"hidden"} m={5}  css={css`
                    animation: ${zoomAndShake} 0.7s ease-out;
                `}>
                <ModalHeader px={0} pt={2} pb={0} textAlign={"center"}>{t("game.congratulations")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody px={2} pt={0} pb={2} overflow="auto">
                    <Text textAlign={"center"}>{t("game.youFinishChallenge")}</Text>                                
                    <Box position="relative">
                        <Image src={"/images/trophy.jpg"} alt={"trophy"} />                    
                        <Badge position="absolute" bottom="4" right="4" bg="#2a9ce1" fontSize="lg" lineHeight="0.8em" color="white" borderRadius={"4"} p={2} minW="30px" textAlign="center" h="30px">+ {xp} XP</Badge>
                    </Box>  
                </ModalBody>
                <ModalFooter px={2} pt={0} pb={2} display="flex">
                        <Button
                            onClick={onClose}
                            size="sm"
                        >
                            {t("game.close")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    )

}