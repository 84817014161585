import { useDisclosure } from '@chakra-ui/react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AuthenticationContext } from '../authentication/authenticationContext';
import { FirebaseContext } from '../firebase/firebaseContext';
import { FriendsType } from './types/friendsType';
import { InviteFriendsModal } from './layout/modal/inviteFriendsModal';
import { useFriendRequests } from './hooks/useFriendRequests';
import { ConfirmDeleteFriend } from './layout/modal/confirmDeleteFriend';
import { GlobalDataUserContext } from '../global/globalDataUserContext';
import { getFriends, getFriendsWithState } from './helper/friendRequestsHelper';
import { UserStateType } from '@/app/types/userStateType';

export const FriendsContext = createContext({} as FriendsType);

export const FriendsProvider = (props:any) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showDeleteFriendRequestUserId, setShowDeleteFriendRequestUserId] = useState("")
    const [friendEmail, setFriendEmail] = useState("")
    const [showModalInviteFriends, setShowModalInviteFriends] = useState(false)
    const [showModalInviteFriendsOutside, setShowModalInviteFriendsOutside] = useState(false)
    const [canShowFriends, setCanShowFriends] = useState(false)

    const { 
        state,
        isLoading: isLoadingFriends
    } = useFriendRequests()

    const { friendRequests, friendRequestsWithUserType:friendRequestWithUser, allUseState:allUserStates } = state;

    useEffect(()=>{
        if ( showModalInviteFriends || showModalInviteFriendsOutside) {
            onOpen()
        }
    },[showModalInviteFriends,showModalInviteFriendsOutside])

    const {
        user,
        isAdmin,
        cloudStorage
    } = useContext(AuthenticationContext)

    const {
        addOrUpdateActionHistory,
        userInfo,
        userState
    } = useContext(GlobalDataUserContext)

    const {
        logAnalyticsEvent
    } = useContext(FirebaseContext)
    
    useEffect(()=>{
        if ( user && user.email){
            setCanShowFriends(true)
        }else{
            setCanShowFriends(false)
        }
    },[user,isAdmin])

    const approveFriend= async(id:string)=>{
        const check = await cloudStorage?.updateFriendRequests(id,"approved")
        if ( check ) {
            logAnalyticsEvent("approve_friend",{})
            addOrUpdateActionHistory("friends",1)
        }
    }

    const friends = useMemo(() => friendRequestWithUser && getFriends(friendRequestWithUser), [friendRequestWithUser]);    
    const friendsWithState = useMemo(() => {
        return user && friendRequestWithUser && getFriendsWithState(
            [...friendRequestWithUser,{
                id: "0",
                senderId: user.uid,
                receiverId: user.uid,
                timestamp: 0,
                user: {
                    id: user.uid,
                    name: userInfo?.userName || user.displayName || '',
                    photoUrl: userInfo?.photoUrl || user.photoURL || '',
                    lastActivity: userInfo?.lastActivity || 0
                },
                state: {
                    userId: userState?.userId || '',
                    points: userState?.points || 0,
                    gooblies: userState?.gooblies || 0,
                    xp: userState?.xp || 0,
                    badges: userState?.badges || [],
                    kilometers: userState?.kilometers || 0,
                    food: userState?.food || 0,
                    lastActivity: userState?.lastActivity || 0
                },
                status: "approved",
                rejectedBy: ""
            }], 
            [...(allUserStates||[]),{
                userId: user.uid,
                points: userState?.points || 0,
                gooblies: userState?.gooblies || 0,
                xp: userState?.xp || 0,
                badges: userState?.badges || [],
                kilometers: userState?.kilometers || 0,
                food: userState?.food || 0,
                lastActivity: userState?.lastActivity || 0
            } as UserStateType]
        );
    }, [friendRequestWithUser, allUserStates, userInfo, userState, user]);

    return (
        <FriendsContext.Provider value={{
            canShowFriends,
            friendRequestWithUser,
            isLoadingFriends,
            showModalInviteFriends,
            showModalInviteFriendsOutside,
            setShowModalInviteFriends,
            setShowModalInviteFriendsOutside,
            friendEmail,
            setFriendEmail,
            showDeleteFriendRequestUserId,
            setShowDeleteFriendRequestUserId,
            approveFriend,
            allUserStates,
            friendRequests,
            friendsWithState,
            friends
        }}>
            {props.children}            
            <InviteFriendsModal
                onClose={onClose}
                onOpen={onOpen}
                isOpen={isOpen}
            />
            <ConfirmDeleteFriend/>
        </FriendsContext.Provider>
    )

}