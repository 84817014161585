import { UserStateType } from "@/app/types/userStateType";
import { FriendRequestWithUserAndLiveScoreType } from "../types/friendRequestWithUserAndLiveScoreType";
import { FriendRequestWithUserType } from "../types/friendRequestWithUserType";

export const getFriendRequests= (friendRequests:FriendRequestWithUserType[]) => {
    const result=[] as FriendRequestWithUserType[]
    if ( friendRequests) {
        friendRequests.forEach((item)=>{
            if ( item.status=="pending"){
                result.push(item)
            }
        })
    }
    return result
}

export const getFriends= (friendRequests:FriendRequestWithUserType[]) => {
    const result=[] as FriendRequestWithUserType[]
    if ( friendRequests) {
        friendRequests.forEach((item)=>{
            if ( item.status=="approved"){
                result.push(item)
            }
        })
    }
    return result
}

export const getFriendsWithState= (friendRequests:FriendRequestWithUserType[], userStates:UserStateType[]|null) => {
    const result=[] as FriendRequestWithUserType[]
    if ( friendRequests) {
        friendRequests.forEach((item)=>{
            const userState=userStates?.find((state)=>state.userId==item.user.id)
            if ( item.status=="approved"){
                result.push({
                    ...item,
                    state:userState||{} as UserStateType
                })
            }
        })
    }
    return result.sort((a:FriendRequestWithUserType, b:FriendRequestWithUserType)=> {
        // Si `a` n'a pas de `state` et `b` en a un, `a` doit passer après `b`
        if (!a.state.points && b.state) {
            return 1;
        }

        // Si `b` n'a pas de `state` et `a` en a un, `b` doit passer après `a`
        if (a.state && !b.state.points) {
            return -1;
        }

        // Si les deux ont un `state`, on les compare par leurs points
        if (a.state.points && b.state.points) {
            return b.state.points - a.state.points;
        }

        // Si ni `a` ni `b` n'ont de `state`, on les laisse dans l'ordre actuel
        return 0;
    })
}

export const getFriendsWithLiveScore= (friendRequests:FriendRequestWithUserAndLiveScoreType[]) => {
    const result=[] as FriendRequestWithUserAndLiveScoreType[]
    if ( friendRequests ) {
        friendRequests.forEach((item)=>{
            if ( item.status=="approved"){
                result.push(item)
            }
        })
    }
    return result
}