import QRCode from "qrcode.react";
import jwt from "jsonwebtoken";
import { useContext, useMemo } from "react";
import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import ScanQrCodeForFriends from "./modal/scanQrCodeForFriend";
import { useTranslation } from "next-i18next";
import { GlobalDataContext } from "../../global/globalDataContext";
import { WebviewType } from "@/app/types/webviewType";

type Props = {
    senderId:string
};

export default function FriendRequestQrCode({ senderId }:Props) {

    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const {
        language
    } = useContext(GlobalDataContext)

    // Génère un jeton JWT
    const token = useMemo(() => {
        try {
            const token = jwt.sign({ senderId }, process.env.NEXT_PUBLIC_FRIENDREQUEST_SECRET_KEY as string, { expiresIn: '1h' });            
            const url = `${process.env.NEXT_PUBLIC_URL}${language}/?friendrequest=${encodeURIComponent(token)}`;
            return url
        } catch (err) {
            console.error("Error generating JWT token:", err);
            return null;
        }
    }, [senderId]);

    //TODO : gérer le chiffrement de données
    const handleShowQRCode = () => {
        if ( window && window.ReactNativeWebView ){
            const data:WebviewType= {
                domain: "GOOBLIES",
                action: "openScanner",
                payload: {
                    title: t("friends.Scan QR Code to add a friend"),
                    cancel: t("friends.Fermer")
                }
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data)); 
        }else {
            onOpen()
        }
    }

    return (
        <Flex flexDir={"column"} alignItems={"center"}>            
            {token && <QRCode value={token} />}
            <Text fontSize="xs" opacity={0.5} py={1}>{t("friends.Scan this QRCode to add me as a friend")}</Text>
            <Button bg={"#eba628!important"} borderRadius="50px" size="sm" onClick={handleShowQRCode}>
                {t("friends.Scan to add friend")}
            </Button>
            <ScanQrCodeForFriends isOpen={isOpen} handleClose={onClose} />
        </Flex>
    );
}
