import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import { PaymentContext } from "@/app/context/payment/paymentContext";
import {
    Box,
    Flex, Modal,
    ModalBody, ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepIcon,
    StepNumber,
    StepTitle,
    StepSeparator, Heading,
    Image,
    Button, Alert,
    AlertIcon,
    VStack,
    HStack, useDisclosure
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext, useEffect, useState } from "react";
//import { ButtonPaymentStripeCheckout } from "../../game/payment/buttonPaymentStripeCheckout";
import { FirebaseContext } from "@/app/context/firebase/firebaseContext";
import { LoginForm } from "../../../authentication/layout/form/loginForm";
import { ButtonPaymentStripeCheckout } from "../buttonPaymentStripeCheckout";
import { ContentPriceList } from "@/app/const/contentPriceList";
import { ContentPriceType } from "../../type/contentPriceType";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import { ButtonPaymentCupcake } from "../buttonPaymentCupcake";
import { ButtonPaymentNativeCheckout } from "../buttonPaymentNativeCheckout";

export default function ShowPaidContentModal() {

    const { t } = useTranslation();

    const {
        showLoginModal,
        setShowLoginModal,
        user,
        anonymousLogin,
        //initFirebaseUi,
        isAuthLoading,
        setLogOut
    } = useContext(AuthenticationContext)
    
    const {
        authState
    } = useContext(FirebaseContext)

    const {
        language,
        categoryId,
        isInWebview,
        setWebviewTransactionSuccess,
        setWebviewPending
    } = useContext(GlobalDataContext)

    const {
        addFood,
        isGlobalDataUserLoading
    }=useContext(GlobalDataUserContext)

    const {
        showPaidContentModal,
        setShowPaidContentModal,
        validPayment,
        errorPayment,
        setErrorPayment,
        setValidPayment,
        addPayment
    } = useContext(PaymentContext)

    const {isOpen:isOpenConfirmBuy, onClose:onCloseConfirmBuy, onOpen:onOpenConfirmBuy} = useDisclosure()
    const [itemDetail, setItemDetail] = useState<ContentPriceType|undefined>(undefined)

    useEffect(()=>{
        if ( showPaidContentModal) {
            const item = ContentPriceList.find((payment) => payment.id === showPaidContentModal.itemId);          
            setItemDetail(item??undefined)
        }else{
            setItemDetail(undefined)
        }
    },[showPaidContentModal])


    const onClose = async() => {
        setShowPaidContentModal(false)
        setErrorPayment(false)
        setValidPayment(false)
    }

    const onCloseWoPay = async() => {
        setShowPaidContentModal(false)
        setErrorPayment(false)
        setValidPayment(false)
    }

    return (
        <>
        {!!showPaidContentModal && itemDetail && <Modal
                isCentered
                size={"sm"}
                isOpen={(!!showPaidContentModal)}
                onClose={onCloseWoPay}
                scrollBehavior="inside"
                closeOnOverlayClick={false} 
                >
                <ModalOverlay />
                <ModalContent maxH="80vh" overflow={"hidden"} >
                    <ModalHeader >
                        <Heading size='sm' textAlign={"center"}>
                             <>{t("payment.buy")} : {t("shopping.title-"+itemDetail.id) as string}</>                            
                        </Heading>
                    </ModalHeader>
                    
                    <ModalBody
                        p={0}
                        overflow="auto"
                    >
                        <Stepper
                            p={3}
                            pt={0}
                            pb={3}
                            index={(user && validPayment)?2:(user ? 1:0)}
                            overflowX={"auto"}
                        >
                                <Step key={0}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink='0'>
                                        <StepTitle>{t("payment.connect")}</StepTitle>
                                    </Box>

                                    <StepSeparator />
                                </Step>

                                <Step key={1}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink='0'>
                                        <StepTitle>
                                            {t("payment.payment")}
                                        </StepTitle>
                                    </Box>

                                    <StepSeparator />
                                </Step>

                                <Step key={2}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink='0'>
                                        <StepTitle>{t("payment.paid")}</StepTitle>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                        </Stepper>
                        {!user &&
                            <>
                                <LoginForm
                                    allowAnonymousLogin={false}
                                />   
                            </>
                        }    

                        {user && !validPayment &&  
                            <>
                                {itemDetail && itemDetail.url && <>
                                    <Box position="relative" w="full" py={5} bg="linear-gradient(180deg, rgb(0 0 0 / 0%) 8%, rgb(128 155 157 / 48%) 100%)" >
                                        <Image 
                                            borderRadius={"10"}
                                            w="auto"
                                            h="200px"
                                            margin="auto" 
                                            border="3px solid white"
                                            src={itemDetail.url} 
                                            boxShadow={"sm"}
                                            alt={"ccupcake"} />
                                        <Flex 
                                            position="absolute"
                                            bottom={3}
                                            right={10}
                                            justifyContent={"center"}
                                        >
                                            <Text 
                                                background={"#ffffff8c"} 
                                                px={4} 
                                                py={2} 
                                                fontSize={"1em"} 
                                                borderRadius={10} 
                                                fontWeight="bold" 
                                                lineHeight={"normal"}
                                                color="black"
                                            >
                                                {t("shopping.detail-"+itemDetail.id)}
                                            </Text>
                                        </Flex>
                                    </Box>  
                                    {itemDetail && itemDetail.description &&
                                        <>
                                            <Box p={3}>
                                                <Alert status='info'>
                                                    <AlertIcon />
                                                    {t("shopping.description-"+itemDetail.id)}
                                                </Alert>
                                            </Box>
                                        </>
                                    }
                                </>}
                            </>}

                        {user && errorPayment &&
                            <>
                                <Box p={3}>
                                    <Alert status='error'>
                                        <AlertIcon />
                                        {t("payment.error")} {/*t("paid.limit")*/}
                                    </Alert>
                                </Box>
                            </>
                        }

                    {user && ( validPayment )&&
                        <>
                            <Box p={3}>
                                <Alert status='success'>
                                    <AlertIcon />
                                    {t("payment.thanks")}  {t("shopping.paid-"+itemDetail.id)}
                                </Alert>   
                            </Box>                             
                        </>
                    }
                    </ModalBody>
                    <ModalFooter                    
                        p={3}
                        pt={0}
                    >
                        <VStack
                            w="full"
                        >
                             <HStack
                                alignSelf={"end"}
                            >
                                { user && !validPayment && 
                                    <Button
                                        mr={0}
                                        onClick={onCloseWoPay}
                                    >
                                        {t("payment.cancel")}
                                    </Button> 
                                }
                                { user && validPayment &&
                                    <Button
                                        mr={0}
                                        onClick={onClose}
                                        colorScheme="linkedin"
                                    >
                                        {t("payment.close")}
                                    </Button> 
                                }
                                { !user &&
                                    <Button
                                        mr={0}
                                        onClick={onClose}
                                    >
                                        {t("payment.close")}
                                    </Button> 
                                }
                                {user && !validPayment && !isInWebview && itemDetail.providerId.stripe && <>
                                    <ButtonPaymentStripeCheckout
                                        content={itemDetail}
                                        categoryId={categoryId}
                                        />
                                </>}
                                {user && !validPayment && isInWebview && itemDetail.providerId.stripe && <>
                                    <ButtonPaymentNativeCheckout
                                        content={itemDetail}
                                        categoryId={categoryId}
                                        />
                                </>}
                                {user && !validPayment && itemDetail.providerId.cupcake && <>
                                    <ButtonPaymentCupcake
                                        content={itemDetail}
                                        categoryId={categoryId}
                                        />
                                </>}
                            </HStack>
                            { user && !validPayment && !isInWebview && itemDetail.providerId.stripe && <Text px={1} fontSize={"xs"} w="full" textAlign={"end"}>{t("payment.redirectToPay")}</Text>}
                            { user && !validPayment && isInWebview && itemDetail.providerId.stripe && <Text px={1} fontSize={"xs"} w="full" textAlign={"end"}>{t("payment.outOfTva")}</Text>}
                        </VStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        }
        </>
    )

}