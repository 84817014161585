import { FriendsContext } from "@/app/context/friends/friendsContext";
import {
    Button,
    Divider,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Flex,
    Text
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext } from "react";
import { SearchBarAddFriend } from "./searchBarAddFriend";
import ShareSocial from "../../../../layout/global/shareSocial";
import FriendRequestQrCode from "../friendRequestQrCode";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";

type Props = {
    isOpen: boolean,
    onOpen: ()=>void,
    onClose: ()=>void
};

export const InviteFriendsModal = ({isOpen, onOpen, onClose}:Props) => {

    const { t } = useTranslation();

    const {
        user
    } = useContext(AuthenticationContext)

    const {
        showModalInviteFriendsOutside,
        setShowModalInviteFriends,
        setShowModalInviteFriendsOutside,
        friendEmail
    } = useContext(FriendsContext)

    const onCloseModal =()=> {
        setShowModalInviteFriends(false)
        setShowModalInviteFriendsOutside(false)
        onClose()
    }

    const subject = encodeURIComponent(t("friends.joinMeOnGooblies"))
    const body = encodeURIComponent(t("friends.joinMeOnGoobliesBody"))
    return (
        <>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onCloseModal}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("friends.inviteYourFriends")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {!showModalInviteFriendsOutside && 
                        <Stack>
                            <Text>{t("friends.inviteFriendsExplanation")} </Text>
                            <SearchBarAddFriend/>
                        </Stack>
                        }  
                        {showModalInviteFriendsOutside && 
                            <Stack
                                alignItems={"center"}
                            >
                                <Text mb={4}>{t("friends.friendNotOnGame")}</Text>
                                <Link target={"_blank"} href={"mailto:"+ encodeURIComponent(friendEmail) +"?subject="+subject+"&body="+body}><Button>{t("friends.sendMail")}</Button></Link>
                                <Divider my={4}/>
                                <ShareSocial/>
                            </Stack>
                        }
                        {user && <>
                            <Divider pt={2} borderColor="lightGrey"/>
                            <Flex pt={1} pb={2} w="full" color="black" opacity="0.5" fontSize={"sm"} placeContent={"center"}>
                                {t("authentication.Ou")}
                            </Flex>
                            <FriendRequestQrCode senderId={user.uid}/>
                        </>}
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
