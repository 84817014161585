import { ChallengeType } from "../types/challengeType";

export const AllChallengesConst: ChallengeType[] = [
    {
        code: "walk",
        title: {
            fr: "Marche {{x}} kilomètres en jouant.",
            nl: "Wandel {{x}} kilometer terwijl je speelt.",
            en: "Walk {{x}} kilometers while playing.",
            de: "Gehe {{x}} Kilometer beim Spielen.",
            it: "Cammina {{x}} chilometri giocando.",
            es: "Camina {{x}} kilómetros mientras juegas."
        },
        quantity: [1, 1.5, 2, 2.5, 5],
        points: 1000
    },
    {
        code: "feed",
        title: {
            fr: "Nourris {{x}} de tes Gooblies.",
            nl: "Voer {{x}} van je Gooblies.",
            en: "Feed {{x}} of your Gooblies.",
            de: "Füttere {{x}} deiner Gooblies.",
            it: "Nutri {{x}} dei tuoi Gooblies.",
            es: "Alimenta {{x}} de tus Gooblies."
        },
        quantity: [1, 5, 10, 20, 50],
        points: 100
    },
    {
        code: "quiz",
        title: {
            fr: "Réponds correctement à {{x}} quiz lors de quêtes.",
            nl: "Beantwoord {{x}} quizzen correct tijdens quests.",
            en: "Answer {{x}} quizzes correctly during quests.",
            de: "Beantworte {{x}} Quizfragen korrekt während Quests.",
            it: "Rispondi correttamente a {{x}} quiz durante le missioni.",
            es: "Responde correctamente a {{x}} cuestionarios durante las misiones."
        },
        quantity: [1, 2, 3, 4, 5],
        points: 500
    },
    {
        code: "cultural",
        title: {
            fr: "Rends-toi à {{x}} points culturels indiqués sur la carte en mode exploration.",
            nl: "Bezoek {{x}} culturele punten op de kaart in exploratiemodus.",
            en: "Visit {{x}} cultural spots marked on the map in exploration mode.",
            de: "Besuche {{x}} kulturelle Punkte auf der Karte im Erkundungsmodus.",
            it: "Visita {{x}} luoghi culturali sulla mappa in modalità esplorazione.",
            es: "Visita {{x}} puntos culturales marcados en el mapa en modo exploración."
        },
        quantity: [1, 2, 3, 4, 5],
        points: 250
    },
    {
        code: "incubate",
        title: {
            fr: "Couve {{x}} de tes oeufs de Gooblies.",
            nl: "Broed {{x}} van je Gooblies-eieren uit.",
            en: "Incubate {{x}} of your Gooblies' eggs.",
            de: "Brüte {{x}} deiner Gooblies-Eier aus.",
            it: "Incuba {{x}} delle tue uova di Gooblies.",
            es: "Incuba {{x}} de tus huevos de Gooblies."
        },
        quantity: [1, 2, 3, 4, 5],
        points: 250
    },
    {
        code: "chase",
        title: {
            fr: "Fais {{x}} chasses de Gooblies.",
            nl: "Doe {{x}} Gooblies-jachten.",
            en: "Do {{x}} Gooblies hunts.",
            de: "Mache {{x}} Gooblies-Jagden.",
            it: "Fai {{x}} cacce ai Gooblies.",
            es: "Realiza {{x}} cazas de Gooblies."
        },
        quantity: [1, 2, 3],
        points: 250
    },
    {
        code: "laugh",
        title: {
            fr: "Donne de la joie à {{x}} de tes Gooblies.",
            nl: "Breng vreugde aan {{x}} van je Gooblies.",
            en: "Bring joy to {{x}} of your Gooblies.",
            de: "Bereite {{x}} deiner Gooblies Freude.",
            it: "Porta gioia a {{x}} dei tuoi Gooblies.",
            es: "Da alegría a {{x}} de tus Gooblies."
        },
        quantity: [1, 5, 10, 20, 50],
        points: 100
    },
    {
        code: "eggs",
        title: {
            fr: "Trouve {{x}} oeufs de Gooblies.",
            nl: "Vind {{x}} Gooblies-eieren.",
            en: "Find {{x}} Gooblies' eggs.",
            de: "Finde {{x}} Gooblies-Eier.",
            it: "Trova {{x}} uova di Gooblies.",
            es: "Encuentra {{x}} huevos de Gooblies."
        },
        quantity: [1, 2, 3, 4, 5],
        points: 250
    },
    {
        code: "gooblies",
        title: {
            fr: "Attrape {{x}} Gooblies.",
            nl: "Vang {{x}} Gooblies.",
            en: "Catch {{x}} Gooblies.",
            de: "Fange {{x}} Gooblies.",
            it: "Cattura {{x}} Gooblies.",
            es: "Atrapa {{x}} Gooblies."
        },
        quantity: [1, 2, 3, 4, 5],
        points: 250
    },
    {
        code: "friends",
        title: {
            fr: "Ajoute {{x}} amis à ta liste d'amis.",
            nl: "Voeg {{x}} vrienden toe aan je vriendenlijst.",
            en: "Add {{x}} buddies to your friends list.",
            de: "Füge {{x}} Freunde zu deiner Freundesliste hinzu.",
            it: "Aggiungi {{x}} amici alla tua lista di amici.",
            es: "Añade {{x}} amigos a tu lista de amigos."
        },
        quantity: [1, 2, 3, 4, 5],
        points: 250
    },
    {
        code: "quest",
        title: {
            fr: "Accomplis {{x}} quêtes.",
            nl: "Voltooi {{x}} quests.",
            en: "Complete {{x}} quests.",
            de: "Schließe {{x}} Quests ab.",
            it: "Completa {{x}} missioni.",
            es: "Completa {{x}} misiones."
        },
        quantity: [1, 2, 3, 4],
        points: 500
    }
]
